<template>
  <div class="d-flex justify-center align-center" style="height: 100%">
    <vcad-dialog v-model="openDialog" title="Templates" dialogWidth="1100">
      <template v-slot:content>
        <vcad-template
          :vcadData="selectedVcadItem"
          :vcadViewList="selectedViewList"
        ></vcad-template>
      </template>
      <template v-slot:actions>
        <div class="d-inline-flex justify-end" style="width: 100%">
          <v-btn
            outlined
            small
            color=""
            class="vcad-btn"
            @click.stop="openDialog = false"
            >CLOSE</v-btn
          >
        </div>
      </template>
    </vcad-dialog>
    <v-data-table
      :items="userFederations"
      height="700"
      :items-per-page="-1"
      hide-default-footer
      style="width: 90%"
      :headers="headers"
      class="elevation-1 vcad-table mx-16"
      item-key="multimodelProjectGuid"
      :loading="fedsLoading"
      show-expand
      :expanded.sync="expanded"
      v-if="headers"
      :search="search"
      :custom-filter="customFilter"
    >
      <!-- expanded items on table -->

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
            :headers="expansionTableHeaders"
            :items="item.slots"
            class="elevation-0 my-5"
            dense
          >
            <template v-slot:[`item.FileName`]="{ item }">
              <div class="d-flex justify-center align-center flex-row">
                <div class="d-flex">
                  <v-img
                    max-height="20"
                    max-width="20"
                    contain
                    class="mr-2"
                    :src="getFileIconExt(item.fileExt)"
                  ></v-img>
                  <div>
                    {{ item.FileName }}
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:[`item.expanded-actions`]="{ item: slotItem }">
              <v-btn
                outlined
                small
                color="primary"
                class="vcad-btn file-action-btn"
                @click="openTemplateSelector(slotItem)"
              >
                Templates
              </v-btn>
              <v-btn
                outlined
                small
                color="primary"
                class="vcad-btn file-action-btn"
                @click="setModelAndShow(slotItem)"
                >View</v-btn
              >
            </template>
          </v-data-table>
        </td>
      </template>

      <!-- top bar table style -->

      <template v-slot:top>
        <div
          class="primary justify-space-between align-center d-flex lighten-2 vcad-table-top pa-3"
        >
          <div>
            <h3 class="white--text">Federation Management</h3>
          </div>
          <div class="d-flex align-center">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search by federation or file SLOT ID"
              hide-details
              clearable
              solo
              dense
              style="width: 400px"
            ></v-text-field>
          </div>
        </div>
      </template>

      <!-- Actions of table -->

      <template v-slot:[`item.actions`]="{ item }">
        
       
            
                <vcad-multi-model-dialog
                  @dialogClosed="dialogClosed"
                  :userFederations="userFederations"
                  :folderFiles="tmpFileList"
                  :folderId="folderId"
                >
                  <template v-slot:fedDialogActivator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      class="vcad-btn"
                      outlined
                      elevation=""
                      max-width="12em"
                      color="primary"
                      @click="setupDialog(item)"
                    >
                      <v-icon>mdi-hexagon-multiple</v-icon>
                      Manage
                    </v-btn>
                  </template>
                </vcad-multi-model-dialog>

                <v-btn
                  small
                  class="vcad-btn"
                  outlined
                  elevation=""
                  max-width="12em"
                  color="primary"
                  @click="goToFolder(item)"
                >
                  <v-icon>mdi-folder</v-icon>
                  Go to folder
                </v-btn>
        
      

         
         
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VcadMultiModelDialog from "./multimodel/VcadMultiModelDialog.vue";
import VcadTemplate from "./VcadTemplate.vue";
import VcadDialog from "./VcadDialog.vue";
import { getFileIconExt } from "../utils/GetFileIconExtension";

export default {
  props: {},
  components: {
    VcadTemplate,
    VcadMultiModelDialog,
    VcadDialog,
  },
  data() {
    return {
      tmpFileList: [],
      idFileSearch: null,
      search: "",
      openDialog: false,
      loadingButton: false,
      showTemplateDialog: false,
      fedsLoading: false,
      expanded: [],
      folderId: "",
      selectedVcadItem: null,
      selectedViewList: null,
      headers: [
        {
          text: "Federation name",
          value: "projectName",
          align: "center",
          width: "20%",
        },
        {
          text: "Description",
          value: "projectDescription",
          align: "center",
        },
        {
          text: "Federation GUID",
          value: "multimodelProjectGuid",
          align: "center",
        },
        {
          text: "Folder",
          value: "folderName",
          align: "center",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
        },
      ],
      expansionTableHeaders: [
        {
          text: "File name",
          value: "FileName",
          align: "center",
        },
        {
          text: "Slot ID",
          value: "Guid",
          align: "center",
        },
        {
          text: "Actions",
          value: "expanded-actions",
          align: "center",
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      "setSelectedMMProject",
      "setUserMMProjects",
      "fetchNodesNew",
      "setShowTemplates",
      "setCurrentViews",
      "setVcadTemplateList",
      "showSnackbar",
      "setMMSlotsNotFound",
      "setFileData",
      "setShowPreview",
    ]),
    // Set the current model and show preview.
    async setModelAndShow(vcadFileInfo) {
      console.log(vcadFileInfo);
      let payloadData = JSON.parse(vcadFileInfo.vcadItem.info).b360Item;
      payloadData.vcadItem = vcadFileInfo;
      await this.setFileData({ slot: 1, data: payloadData });
      await this.setShowPreview({ show: true, mode: 0 });
    },
    goToFolder(fed) {
      const firstSlot = fed.slots[0].vcadItem;
      const info = JSON.parse(firstSlot.info);
      const parentId = info.b360Item.parentId;
      const projectId = info.b360Item.projectId;
      if (parentId) {
        this.$router.push(`/b360-doc-manager/${projectId}/${parentId}`);
      } else {
        this.showSnackbar({
          text: "Sorry, redirection is not available for this file. Please try restarting the file conversion process.",
          color: "warning",
          timeoutTime: 6000,
        });
      }
    },
    getFileIconExt(ext) {
      return getFileIconExt(ext);
    },
    loadFederations() {
      this.fedsLoading = true;
      this.setUserMMProjects().finally(() => {
        this.fedsLoading = false;
      });
    },
    dialogClosed() {
      this.loadFederations();
    },
    // async downloadCsvTemplate() {
    //   this.loadingButton = true;
    //   let u = await this.$refs.vcadTemplate.downloadCsvZipViews();
    //   this.loadingButton = u;
    // },
    async openTemplateSelector(item) {
      // Load and set current views
      this.selectedVcadItem = item.vcadItem;
      this.selectedViewList = item.viewList;

      // Set template list
      await this.setVcadTemplateList(item.vcadItem.type);

      // Open template selector component
      this.openDialog = true;
    },
    async setupDialog(federation) {
      this.folderId = this.decodeFolderId(federation.folderGuid);
      try {
        const promiseList = [
          this.getFolderFiles(this.folderId),
          this.setSelectedMMProject(federation),
        ];
        const resultList = await Promise.all(promiseList);
        this.tmpFileList = resultList[0];
      } catch (error) {
        this.tmpFileList = [];
      }
    },
    async getFolderFiles(folderId) {
      let item = {
        id: folderId,
        projectId: this.selectedProject.itemId,
        type: "Folder",
      };
      const fileTypeNames = [this.itemTypeName, this.documentTypeName];
      let fileList = await this.fetchNodesNew({ item });
      return fileList
        .filter((item) => {
          return fileTypeNames.includes(item.type);
        })
        .map((item) => {
          let options = {
            dateStyle: "medium",
            timeStyle: "short",
            hour12: false,
          };
          item.lastUpdateDate = new Date(item.lastUpdateDate).toLocaleString(
            window.navigator.language,
            options
          );

          //load image for each card (card modality visualization)
          //   item.imageBase64 = "";
          // this.setThums(item).then((base64) => {
          //   item.imageBase64 = base64;
          // });
          return item;
        });
    },
    decodeFolderId(encodedId) {
      if (!encodedId) {
        return null;
      }
      return atob(encodedId);
    },
    customFilter(value, search, item) {
      if (!search) {
        return true;
      }
      // Check row
      let itemProps = [
        item.projectName,
        item.projectDescription,
        item.projectId,
        item.multimodelProjectGuid,
        item.folderName,
      ];
      const rowIsValid =
        JSON.stringify(itemProps)
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1;

      // Check Slod ID/Guid
      const hasValidSlots = item.slots.some(
        (tmp) => tmp.Guid.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
      return rowIsValid || hasValidSlots;
    },
  },
  watch: {
    vcadUserInfo() {
      this.loadFederations();
    },
  },
  computed: {
    ...mapGetters([
      "userMMProjects",
      "vcadUserInfo",
      "selectedProject",
      "itemTypeName",
      "documentTypeName",
      "showTemplates",
      "axiosSettings",
    ]),
    userFederations() {
      return this.userMMProjects.map((fed) => {
        let parsedData = JSON.parse(fed.data);
        let tmpFed = {
          multimodelProjectGuid: fed.projectId,
          projectName: parsedData.projectName,
          projectDescription: parsedData.projectDescription,
          slots: parsedData.slots,
          folderGuid: fed.folderId,
          folderName: parsedData.folderName,
        };
        return tmpFed;
      });
    },
    userSlots() {
      return this.userMMProjects.map((fed) => {
        let parsedData = JSON.parse(fed.data);
        let slots = parsedData.slots;
        return slots;
      });
    },
  },
  mounted() {
    this.loadFederations();
  },
};
</script>

<style lang="scss" scoped>
.vcad-table-top {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.v-tooltip__content {
  border: 1px solid grey;
}
.v-card--reveal {
  align-items: center;
  position: absolute;
  justify-content: center;
  right: 0;
  bottom: 0;
  z-index: 0;
}
</style>